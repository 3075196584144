import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RestService } from '../../../services/rest.service';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MenuService } from 'src/app/services/menu.service';
import { ErrorService } from 'src/app/services/error.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  user: any;

  myDocuments: any;
  myResources: any;
  myTimeTracks: any;
  myNewIdeas: any;

  constructor(@Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2, 
              public router: Router,
              private restService: RestService,
              private menuService: MenuService,
              private errorService: ErrorService
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  refreshMenu(): void {
    this.menuService.getNavChangeEmitter().subscribe(() => {
      this.restService.get('/api/users/getMenuItemsBadges').subscribe((resp: any) => {

        this.user = AuthService.getUserObject();
        // console.log(this.user);
        // console.log(resp.data);

        this.myDocuments =  resp.data.myDocuments;
        this.myResources = resp.data.myResources;
        this.myTimeTracks = resp.data.myTimeTracks;
        this.myNewIdeas = resp.data.myNewIdeas;


        this.menuItems = MENU;
        for (const item of this.menuItems){
          /**
           * Badges
           */
          if (item.link === '/userDocuments'){
            item.badge =  { text: this.myDocuments, variant: 'light'};
          }

          // if (item.link == '/resources'){
          //   item.badge =  { text: this.myResources, variant: 'danger'};
          // }

          if (item.link === '/dashboard' && this.myTimeTracks){
            item.badge = { text: this.myTimeTracks, variant: 'danger'};
          }else{
            item.badge = '';
          }

          if (item.link === '/ideas' && this.myNewIdeas){
            item.badge = { text: this.myNewIdeas, variant: 'danger'};
          }else{
            item.badge = '';
          }


          /**
           * Permission control
           */
          if (item.link === '/adminResources' && !AuthService.userHasPermission(this.user, 3)){ // Recursos
            this.menuItems = this.menuItems.filter((i: any) => {
              return i.link !== '/adminResources';
            });
          }
          if (item.link === '/users' && !AuthService.userHasPermission(this.user, 4)){ // Usuarios
            this.menuItems = this.menuItems.filter((i: any) => {
              return i.link !== '/users';
            });
          }
        }

        if (
          !AuthService.userHasPermission(this.user, 3) &&
          !AuthService.userHasPermission(this.user, 4)
        ){
          this.menuItems = this.menuItems.filter((i: any) => {
            return i.label !== 'Administración';
          });
        }

        /**
         * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
         */
        const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
        desktopMedium.addListener(this.iconSidebar);
        this.iconSidebar(desktopMedium);

        this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
        this.document.body.classList.add('sidebar-dark');


      },
      (error: any) => {
        console.log(error);
        this.errorService._error('warning', 'menuItemBadges - get() error', error.message);
      });
    });
  }

  async ngOnInit(): Promise<void> {
    this.refreshMenu();
    this.menuService.emitNavChangeEvent();

  }

  ngAfterViewInit(): void {
    // activate menu item
    // tslint:disable-next-line:no-unused-expression
    new MetisMenu(this.sidebarMenu.nativeElement);
    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e): void {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e): void {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded(): void {
    if (this.document.body.classList.contains('sidebar-folded')){
      this.document.body.classList.add('open-sidebar-folded');
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded(): void {
    if (this.document.body.classList.contains('sidebar-folded')){
      this.document.body.classList.remove('open-sidebar-folded');
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e): void {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event): void {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value); // sidebar-dark
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem): boolean {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown(): void {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems(): void {

    const links = document.getElementsByClassName('nav-link-ref');

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
          parentEl.classList.remove('mm-active');
          const parent2El = parentEl.parentElement;

          if (parent2El) {
            parent2El.classList.remove('mm-show');
          }

          const parent3El = parent2El.parentElement;
          if (parent3El) {
            parent3El.classList.remove('mm-active');

            if (parent3El.classList.contains('side-nav-item')) {
              const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

              if (firstAnchor) {
                firstAnchor.classList.remove('mm-active');
              }
            }

            const parent4El = parent3El.parentElement;
            if (parent4El) {
              parent4El.classList.remove('mm-show');

              const parent5El = parent4El.parentElement;
              if (parent5El) {
                parent5El.classList.remove('mm-active');
              }
            }
          }
      }
    }
  }

  call(method): void {
    if (method === 'signout'){
      localStorage.removeItem(environment.localStorageFlagName);

      if (!localStorage.getItem(environment.localStorageFlagName)) {
        this.router.navigate(['/auth/login']);
      }
    }
  }


  /**
   * Toggles the menu items
   */
  activateMenuItems(): void {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
        if (window.location.pathname === links[i]['pathname']) {

            menuItemEl = links[i];

            break;
        }
    }

    if (menuItemEl) {
        menuItemEl.classList.add('mm-active');
        const parentEl = menuItemEl.parentElement;

        if (parentEl) {
            parentEl.classList.add('mm-active');

            const parent2El = parentEl.parentElement;
            if (parent2El) {
                parent2El.classList.add('mm-show');
            }

            const parent3El = parent2El.parentElement;
            if (parent3El) {
                parent3El.classList.add('mm-active');

                if (parent3El.classList.contains('side-nav-item')) {
                    const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

                    if (firstAnchor) {
                        firstAnchor.classList.add('mm-active');
                    }
                }

                const parent4El = parent3El.parentElement;
                if (parent4El) {
                    parent4El.classList.add('mm-show');

                    const parent5El = parent4El.parentElement;
                    if (parent5El) {
                        parent5El.classList.add('mm-active');
                    }
                }
            }
        }
    }
  }


}
